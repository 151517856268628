import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, bodyFontFamily } from "../utils/typography"

import * as styles from "./index.module.css"
import { MDXRenderer } from "gatsby-plugin-mdx";

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMdx.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Welcome to The Scubaneer" />

      <header className={styles.header}>
        <h1 className={styles.welcome}>
          <span className={styles.welcomeLight}>Welcome to</span> <br></br>
          <span>The Scubaneer</span>
        </h1>
        <div className={styles.coverImage}>
          <GatsbyImage
            image={data.coverImage.childImageSharp.gatsbyImageData}
            style={{ width: '100%', height: '100%' }}
            imgStyle={{
              objectFit: 'cover',
              objectPosition: '80% 50%'
            }} />
        </div>
        { data.aboutPage?.context?.slug && (
          <Link to={data.aboutPage.context.slug} className={`${styles.aboutCta} ${styles.cta}`} style={{ fontFamily: bodyFontFamily }}>
            <div>
              About The Scubaneer
            </div>
          </Link>
        )}
      </header>
      <main>
        <section>
          <h2 className={styles.sectionHeader}>
            Recent articles
          </h2>
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            return (
              <article key={node.fields.slug}>
                <header>
                  <h3
                    style={{
                      marginBottom: rhythm(1 / 4),
                    }}
                  >
                    <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                      {title}
                    </Link>
                  </h3>
                  <small><time dateTime={node.frontmatter.isoDate}>{node.frontmatter.date}</time></small>
                </header>
                { node.frontmatter.featured_image && (
                  <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                    <GatsbyImage
                      image={node.frontmatter.featured_image.childImageSharp.gatsbyImageData}
                      imgStyle={{ objectFit: 'cover' }}
                      alt=""
                      className={styles.blogFeaturedImage} />
                  </Link>
                )}
                <MDXRenderer>{node.fields.markdownExcerpt.childMdx.body}</MDXRenderer>
                <Link className={`${styles.readMoreCta} ${styles.cta}`} to={node.fields.slug}>
                  Read more
                </Link>
              </article>
            );
          })}
        </section>
      </main>
    </Layout>
  );
}

export default BlogIndex

export const pageQuery = graphql`{
  site {
    siteMetadata {
      title
    }
  }
  aboutPage: sitePage(path: {eq: "/about-the-scubaneer/"}) {
    context {
      slug
    }
  }
  coverImage: file(relativePath: {eq: "cover-image.jpeg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 410
        quality: 95
        tracedSVGOptions: {color: "#9ba8b3"}
        placeholder: TRACED_SVG
        layout: CONSTRAINED
      )
    }
  }
  allMdx(
    filter: {fields: {collection: {eq: "blog"}}}
    sort: {fields: [frontmatter___date], order: DESC}
  ) {
    edges {
      node {
        fields {
          slug
          markdownExcerpt {
            childMdx {
              body
            }
          }
        }
        frontmatter {
          isoDate: date
          date(formatString: "MMMM DD, YYYY")
          title
          description
          featured_image {
            childImageSharp {
              gatsbyImageData(
                width: 420
                quality: 95
                tracedSVGOptions: {color: "#9ba8b3"}
                placeholder: TRACED_SVG
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  }
}
`
